import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  enableCustomizationsOnAssociatedEditor(editorIssuedEvent) {
    const { toolbarElement } = editorIssuedEvent.target
    this.enableLocalUrlsOn(toolbarElement)
  }
  
  // Hat-tip: https://github.com/basecamp/trix/issues/624
  enableLocalUrlsOn(toolbarElement) {
    const inputElement = toolbarElement.querySelector("input[name=href]")
    inputElement.type = "text"
    inputElement.pattern = "(https?://|/).+"
  }
}